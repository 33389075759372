import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

const API_BASE_URL = process.env.REACT_APP_API_URL;

interface Teebox {
  course_teebox_id?: number;
  course_id?: number;
  course_teebox_name: string;
  course_teebox_yards: number;
  course_teebox_par: number;
  course_teebox_rating: number;
  course_teebox_slope: number;
  hole_1_par?: number;
  hole_1_yards?: number;
  hole_2_par?: number;
  hole_2_yards?: number;
  hole_3_par?: number;
  hole_3_yards?: number;
  hole_4_par?: number;
  hole_4_yards?: number;
  hole_5_par?: number;
  hole_5_yards?: number;
  hole_6_par?: number;
  hole_6_yards?: number;
  hole_7_par?: number;
  hole_7_yards?: number;
  hole_8_par?: number;
  hole_8_yards?: number;
  hole_9_par?: number;
  hole_9_yards?: number;
  hole_10_par?: number;
  hole_10_yards?: number;
  hole_11_par?: number;
  hole_11_yards?: number;
  hole_12_par?: number;
  hole_12_yards?: number;
  hole_13_par?: number;
  hole_13_yards?: number;
  hole_14_par?: number;
  hole_14_yards?: number;
  hole_15_par?: number;
  hole_15_yards?: number;
  hole_16_par?: number;
  hole_16_yards?: number;
  hole_17_par?: number;
  hole_17_yards?: number;
  hole_18_par?: number;
  hole_18_yards?: number;
}

interface Course {
  course_id?: number;
  course_name: string;
  teeboxes?: Teebox[];
}

const Courses: React.FC = () => {
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const [courses, setCourses] = useState<Course[]>([]);
  const [loading, setLoading] = useState(true);
  const [expandedCourses, setExpandedCourses] = useState<{ [key: number]: boolean }>({});


  useEffect(() => {
    if (isAuthenticated) {
      fetchCourses();
    }
  }, [isAuthenticated]);

  const fetchCourses = async () => {
    try {
      const token = await getAccessTokenSilently();
      const res = await axios.get(`${API_BASE_URL}/coursesv2`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCourses(res.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const updateCourse = async (course: Course) => {
    try {
      const token = await getAccessTokenSilently();
      await axios.put(`${API_BASE_URL}/courses/${course.course_id}`, course, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert('Course updated!');
    } catch (error) {
      console.error('Failed to update course:', error);
    }
  };

  const updateTeebox = async (teebox: Teebox) => {
    try {
      const token = await getAccessTokenSilently();
      await axios.put(`${API_BASE_URL}/teeboxes/${teebox.course_teebox_id}`, teebox, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert('Teebox updated!');
    } catch (error) {
      console.error('Failed to update teebox:', error);
    }
  };

  const addTeebox = async (course_id?: number) => {
    if (!course_id) return;

    const newTeebox: Teebox = {
      course_id,
      course_teebox_name: "",
      course_teebox_yards: 0,
      course_teebox_par: 0,
      course_teebox_rating: 0,
      course_teebox_slope: 0
    };

    try {
      const token = await getAccessTokenSilently();
      await axios.post(`${API_BASE_URL}/teeboxes`, newTeebox, {
        headers: { Authorization: `Bearer ${token}` }
      });

      alert('New teebox added!');
      fetchCourses(); // Refresh to reflect new teebox
    } catch (error) {
      console.error('Failed to add teebox:', error);
    }
  };

  const addCourse = () => {
    const newCourse: Course = {
      course_name: "",
      teeboxes: undefined
    };

    setCourses([newCourse, ...courses]);
  };

  const saveNewCourse = async (course: Course) => {
    try {
      const token = await getAccessTokenSilently();
      await axios.post(`${API_BASE_URL}/courses`, course, {
        headers: { Authorization: `Bearer ${token}` }
      });

      alert('New course added!');
      fetchCourses();
    } catch (error) {
      console.error('Failed to add course:', error);
    }
  };

  const toggleTeeboxVisibility = (courseId: number) => {
    setExpandedCourses((prev) => ({
      ...prev,
      [courseId]: !prev[courseId],
    }));
  };

  if (!isAuthenticated) {
    return (
      <div>
        <h1>You must be logged in to access this page</h1>
        <button onClick={() => loginWithRedirect()}>Log In</button>
      </div>
    );
  }

  return (
    <div>
      <h1>Edit Courses & Teeboxes</h1>

      <button 
        onClick={addCourse}
        style={{
          width: '100%', 
          padding: '10px', 
          backgroundColor: '#28a745', 
          color: 'white', 
          border: 'none',
          cursor: 'pointer',
          marginBottom: '20px',
          fontWeight: 'bold'
        }}
      >
        + Add New Course
      </button>

      {loading ? <p>Loading...</p> : (
        courses.map((course, courseIndex) => (
          <div key={courseIndex} style={{ border: '1px solid #ccc', padding: '20px', marginBottom: '20px' }}>
            <h2>{course.course_name || "New Course"}</h2>

            <label>Course Name:</label>
            <input 
              type="text" 
              value={course.course_name} 
              onChange={(e) => setCourses(courses.map((c, i) => i === courseIndex ? { ...c, course_name: e.target.value } : c))}
              style={{ width: '250px', marginBottom: '10px' }}
            />

            {course.course_id ? (
              <button onClick={() => updateCourse(course)}>Save Course</button>
            ) : (
              <button onClick={() => saveNewCourse(course)}>Create Course</button>
            )}

            {course.course_id && (
              <>
                <h3>Teeboxes</h3>
                {(course.teeboxes || []).map((teebox, index) => (
                  <div key={index} style={{
                    marginBottom: '15px',
                    padding: '15px',
                    border: '1px solid #ddd',
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '100%',
                  }}>
                    <label>Teebox Name:</label>
                    <input
                      type="text"
                      value={teebox.course_teebox_name}
                      onChange={(e) => setCourses(courses.map(c => ({
                        ...c,
                        teeboxes: c.teeboxes?.map(t =>
                          t === teebox ? { ...t, course_teebox_name: e.target.value } : t
                        )
                      })))}
                      style={{ width: '100%', marginBottom: '8px', }}
                    />

                    <label>Yardage:</label>
                    <input
                      type="number"
                      value={teebox.course_teebox_yards}
                      onChange={(e) => setCourses(courses.map(c => ({
                        ...c,
                        teeboxes: c.teeboxes?.map(t =>
                          t === teebox ? { ...t, course_teebox_yards: Number(e.target.value) } : t
                        )
                      })))}
                      style={{ width: '100%', marginBottom: '8px' }}
                    />

                    <label>Par:</label>
                    <input
                      type="number"
                      value={teebox.course_teebox_par}
                      onChange={(e) => setCourses(courses.map(c => ({
                        ...c,
                        teeboxes: c.teeboxes?.map(t =>
                          t === teebox ? { ...t, course_teebox_par: Number(e.target.value) } : t
                        )
                      })))}
                      style={{ width: '100%', marginBottom: '8px' }}
                    />

                    <label>Rating:</label>
                    <input
                      type="number"
                      step="0.1"
                      value={teebox.course_teebox_rating}
                      onChange={(e) => setCourses(courses.map(c => ({
                        ...c,
                        teeboxes: c.teeboxes?.map(t =>
                          t === teebox ? { ...t, course_teebox_rating: Number(e.target.value) } : t
                        )
                      })))}
                      style={{ width: '100%', marginBottom: '8px' }}
                    />

                    <label>Slope:</label>
                    <input
                      type="number"
                      value={teebox.course_teebox_slope}
                      onChange={(e) => setCourses(courses.map(c => ({
                        ...c,
                        teeboxes: c.teeboxes?.map(t =>
                          t === teebox ? { ...t, course_teebox_slope: Number(e.target.value) } : t
                        )
                      })))}
                      style={{ width: '100%', marginBottom: '8px' }}
                    />

<div style={{
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(80px, 1fr))',
    gap: '10px',
    maxWidth: '100%',
    border: '1px solid #ddd',
    padding: '15px',
    overflowX: 'auto'
  }}>
{[...Array(18)].map((_, hole) => (
  <div key={hole} style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
    <strong>Hole {hole + 1}</strong>
    <label>Par</label>
    <select
  value={teebox[`hole_${hole + 1}_par`] || ''}
  onChange={(e) => setCourses(courses.map(c => ({
    ...c,
    teeboxes: c.teeboxes?.map(t =>
      t === teebox ? { ...t, [`hole_${hole + 1}_par`]: Number(e.target.value) } : t
    )
  })))}
  style={{ width: '100%' }}
>
  <option value="" disabled></option>
  <option value={3}>3</option>
  <option value={4}>4</option>
  <option value={5}>5</option>
</select>
    <label>Yards</label>
    <input
      type="number"
      value={teebox[`hole_${hole + 1}_yards`] || ''}
      onChange={(e) => setCourses(courses.map(c => ({
        ...c,
        teeboxes: c.teeboxes?.map(t =>
          t === teebox ? { ...t, [`hole_${hole + 1}_yards`]: Number(e.target.value) } : t
        )
      })))}
      style={{ width: '100%' }}
      min={1}
    />
  </div>
))}
  </div>


                    <button onClick={() => updateTeebox(teebox)}>Save Teebox</button>
                  </div>
                  
                ))}
                

                <button onClick={() => addTeebox(course.course_id)}>+ Add New Teebox</button>
              </>
            )}
          </div>
          
        ))
      )}
    </div>

    
  );
};

export default Courses;
