import axios from 'axios';
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAuth0 } from '@auth0/auth0-react';

interface DropdownItem {
  course_id: string;
  course_name: string;
  course_teebox_id: string;
  course_teebox_name: string;
}

interface Player {
  player_id: number;
  full_name: string;
  updatedScores: number;
}

interface Tournament {
  tournament_id: string;
  tournament_name: string;
  start_date: string;
  end_date: string;
}

interface DropdownComponentProps {
  onClose: () => void;
}

const DropdownComponent: React.FC<DropdownComponentProps> = ({ onClose }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [dropdownData, setDropdownData] = useState<DropdownItem[]>([]);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [players, setPlayers] = useState<Player[]>([]);
  const [selectedPlayers, setSelectedPlayers] = useState<(number | null)[]>(Array(2).fill(null));
  const [playerScores, setPlayerScores] = useState<string[]>(Array(2).fill(''));
  const [playerDQ, setPlayerDQ] = useState<boolean[]>(Array(2).fill(false));
  const [playerFields, setPlayerFields] = useState(Array.from({ length: 2 }));
  const [selectedTournamentId, setSelectedTournamentId] = useState<string>('');
  const [selectedMajorIdentifier, setSelectedMajorIdentifier] = useState<string>('');
  const [tournaments, setTournaments] = useState<Tournament[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dropdownTeeboxData, setDropdownTeebox] = useState<DropdownItem[]>([]);
  const [teeBoxSelectedValue, teeBoxSetSelectedValue] = useState<string>('');
  const [date, setDate] = useState<Date | null>(new Date());
  const [leftyVsRighty, setLeftyVsRighty] = useState<boolean>(false);
  const [numberOfHoles, setNumberOfHoles] = useState<string>('18');
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const APIURL = process.env.REACT_APP_API_URL;

  const clearFieldError = (field: string) => {
    setFormErrors((prev) => {
      const updated = { ...prev };
      delete updated[field];
      return updated;
    });
  };

  const resetForm = () => {
    setSelectedValue('');
    setSelectedTournamentId('');
    setSelectedMajorIdentifier('');
    teeBoxSetSelectedValue('');
    setDate(new Date());
    setNumberOfHoles('18');
    setLeftyVsRighty(false);
    setSelectedPlayers(Array(2).fill(null));
    setPlayerScores(Array(2).fill(''));
    setPlayerDQ(Array(2).fill(false));
    setPlayerFields(Array.from({ length: 2 }));
    setFormErrors({});
  };
  

  useEffect(() => {
    if (selectedMajorIdentifier === 'YES') {
      setLoading(true);
      getAccessTokenSilently().then((token) => {
        axios
          .get(`${APIURL}/tournaments?tournament_status=open`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            setTournaments(response.data);
            setLoading(false);
          })
          .catch((error) => {
            console.error('Error fetching tournaments:', error);
            setLoading(false);
          });
      });
    }
  }, [selectedMajorIdentifier, getAccessTokenSilently]);

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      axios
        .get(`${APIURL}/courses`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => setDropdownData(response.data))
        .catch((error) => console.error('Error fetching courses:', error));
    });
  }, [getAccessTokenSilently]);

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      axios
        .get(`${APIURL}/playersAddedtoRound`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => setPlayers(response.data))
        .catch((error) => console.error('Error fetching players:', error));
    });
  }, [getAccessTokenSilently]);

  const handleSelectPlayer = (playerId: number, index: number) => {
    const newSelectedPlayers = [...selectedPlayers];
    newSelectedPlayers[index] = playerId;
    setSelectedPlayers(newSelectedPlayers);
    clearFieldError(`player_${index}`);
  };

  const handleScoreChange = (value: string, index: number) => {
    const newScores = [...playerScores];
    newScores[index] = value;
    setPlayerScores(newScores);
    if (value !== '' && !isNaN(Number(value))) {
      clearFieldError(`score_${index}`);
    }
  };

  const handleDropdownChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const id = event.target.value;
    setSelectedValue(id);
    clearFieldError('course');

    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      const response = await fetch(`${APIURL}/teeboxes?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data: DropdownItem[] = await response.json();
      setDropdownTeebox(data);
      teeBoxSetSelectedValue('');
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const teeBoxHandleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    teeBoxSetSelectedValue(value);
    clearFieldError('teebox');
  };

  const toggleDQ = (index: number) => {
    const newDQ = [...playerDQ];
    newDQ[index] = !newDQ[index];
    setPlayerDQ(newDQ);
  
    // Only clear validation error — don't clear the score
    if (newDQ[index]) {
      clearFieldError(`score_${index}`);
    }
  };
  

  const validateRoundForm = (): boolean => {
    const errors: { [key: string]: string } = {};
    if (!selectedMajorIdentifier) {
      errors.majorIdentifier = 'Please select YES or NO.';
    }
    if (!date) errors.date = 'Please select a round date.';
    if (!selectedValue) errors.course = 'Please select a course.';
    if (!teeBoxSelectedValue) errors.teebox = 'Please select a tee box.';
    if (selectedMajorIdentifier === 'YES' && !selectedTournamentId) errors.tournament = 'Please select a tournament.';

    if (selectedPlayers.length < 2) {
      errors.players = 'At least two players must be selected.';
    }

    selectedPlayers.forEach((playerId, i) => {
      if (playerId === null) {
        errors[`player_${i}`] = 'Select a player.';
      }
    
      if (!playerDQ[i]) {
        const score = Number(playerScores[i]);
        if (
          playerScores[i] === '' ||
          isNaN(score) ||
          score < 50 ||
          score > 144
        ) {
          errors[`score_${i}`] = 'Score must be between 50 and 144.';
        }
      }
    });

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const saveRoundData = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!validateRoundForm()) return;

    const localDate = new Date(date!.getTime() - date!.getTimezoneOffset() * 60000)
      .toISOString()
      .split('T')[0];

    const roundData = {
      date: localDate,
      course: selectedValue,
      courseteebox: teeBoxSelectedValue,
      tournamentid: selectedTournamentId,
      players: selectedPlayers.map((playerId, index) => ({
        player_id: playerId,
        score: Number(playerScores[index]) || 0,
        is_disqualified: playerDQ[index]
      })),
      lefty_vs_righty: leftyVsRighty,
      number_of_holes: numberOfHoles,
    };

    try {
      const token = await getAccessTokenSilently();
      await axios.post(`${APIURL}/addNewRound`, { roundData }, {
        headers: { Authorization: `Bearer ${token}` },
      });
    
      alert('Your Round has been saved successfully!');
      resetForm();         // Clear the form
      onClose();           // Close the modal
    } catch (error) {
      console.error('Failed to save round data', error);
      alert('Failed to save round data.');
    }
    
  };

  const addPlayerField = () => {
    setPlayerFields((prev) => [...prev, {}]);
    setSelectedPlayers((prev) => [...prev, null]);
    setPlayerScores((prev) => [...prev, '']);
    setPlayerDQ((prev) => [...prev, false]);
  };

  const deletePlayerField = (index: number) => {
    if (selectedPlayers.length <= 2) {
      alert('At least two players are required.');
      return;
    }
    setPlayerFields((prev) => prev.filter((_, i) => i !== index));
    setSelectedPlayers((prev) => prev.filter((_, i) => i !== index));
    setPlayerScores((prev) => prev.filter((_, i) => i !== index));
    setPlayerDQ((prev) => prev.filter((_, i) => i !== index));
  };

  const formatDate = (dateString: string): string => {
    const tournamentdate = new Date(dateString);
    return tournamentdate.toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' });
  };

  const availablePlayers = (index: number): Player[] => {
    return players.filter(
      (p) => !selectedPlayers.includes(p.player_id) || selectedPlayers[index] === p.player_id
    );
  };
  

  return (
    <form id="playerRoundForm">
      <div>
      <div className="form-group">
  <label>Is this associated with a tournament?</label>
  <select
    value={selectedMajorIdentifier}
    onChange={(e) => {
      setSelectedMajorIdentifier(e.target.value);
      clearFieldError('majorIdentifier');
    }}
    className={`form-control ${formErrors.majorIdentifier ? 'is-invalid' : ''}`}
  >
    <option value="" disabled>Select...</option>
    <option value="YES">YES</option>
    <option value="NO">NO</option>
  </select>
  {formErrors.majorIdentifier && (
    <div className="invalid-feedback">{formErrors.majorIdentifier}</div>
  )}
</div>


        {selectedMajorIdentifier === 'YES' && (
  <div className="form-group">
    <label>What tournament is this associated with?</label>
    <select
      disabled={loading}
      className={`form-control ${formErrors.tournament ? 'is-invalid' : ''}`}
      value={selectedTournamentId}
      onChange={(e) => setSelectedTournamentId(e.target.value)}
    >
      <option value="" disabled>Select a tournament...</option>
      {tournaments.map((tournament) => (
        <option key={tournament.tournament_id} value={tournament.tournament_id}>
          {tournament.tournament_name +
            (tournament.start_date ? ` - ${formatDate(tournament.start_date)}` : '') +
            (tournament.end_date ? ` to ${formatDate(tournament.end_date)}` : '')}
        </option>
      ))}
    </select>
    {formErrors.tournament && <div className="invalid-feedback">{formErrors.tournament}</div>}
  </div>
)}
      </div>

      <br />
      <div className="form-group" style={{ marginBottom: '20px' }}>
  <label htmlFor="roundDate">What date was the round played?</label><br />
  <DatePicker
    selected={date}
    onChange={(date) => setDate(date)}
    className={`form-control ${formErrors.date ? 'is-invalid' : ''}`}
  />
  {formErrors.date && <div className="invalid-feedback">{formErrors.date}</div>}
</div>
<div className="form-group">
  <label htmlFor="coursesDropdown">Which course was played?</label>
  <select
    className={`form-select ${formErrors.course ? 'is-invalid' : ''}`}
    id="coursesDropdown"
    value={selectedValue}
    onChange={handleDropdownChange}
  >
    <option value="" disabled hidden>Select a course</option>
    {dropdownData.map((item, index) => (
      <option key={index} value={item.course_id}>
        {item.course_name}
      </option>
    ))}
  </select>
  {formErrors.course && <div className="invalid-feedback">{formErrors.course}</div>}
</div>

        <br />
        <div className="form-group">
  <label htmlFor="courseSelectedWhichBox">Which teebox was played?</label>
  <select
    className={`form-select ${formErrors.teebox ? 'is-invalid' : ''}`}
    id="courseSelectedWhichBox"
    value={teeBoxSelectedValue}
    onChange={teeBoxHandleDropdownChange}
  >
    <option value="" disabled hidden>Select a tee box</option>
    {dropdownTeeboxData.map((item, index) => (
      <option key={index} value={item.course_teebox_id}>
        {item.course_teebox_name}
      </option>
    ))}
  </select>
  {formErrors.teebox && <div className="invalid-feedback">{formErrors.teebox}</div>}
</div>

        <br />
        <label htmlFor="holesDropdown" className="form-label">How many holes were played?</label>
        <select
          className="form-select"
          id="holesDropdown"
          value={numberOfHoles}
          onChange={(e) => setNumberOfHoles(e.target.value)}
          required
        >
          <option value="18">18</option>
          <option value="9">9</option>
        </select>
        <br />
        <h5>Who played in the round?</h5>
        <div>
          {playerFields.map((_, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <select
  value={selectedPlayers[index] !== null ? String(selectedPlayers[index]) : ''}
  onChange={(e) => handleSelectPlayer(Number(e.target.value), index)}
  className={`form-select ${formErrors[`player_${index}`] ? 'is-invalid' : ''}`}
>
  <option value="" disabled>Select a Player</option>
  {availablePlayers(index).map((player) => (
    <option key={player.player_id} value={player.player_id}>
      {player.full_name}
    </option>
  ))}
</select>

{formErrors[`player_${index}`] && (
  <div className="invalid-feedback">{formErrors[`player_${index}`]}</div>
)}

<input
  type="number"
  min="50"
  max="144"
  value={playerScores[index] ?? ''}
  onChange={(e) => handleScoreChange(e.target.value, index)}
  className={`form-control ${formErrors[`score_${index}`] ? 'is-invalid' : ''}`}
  style={{ marginLeft: '10px' }}
  onKeyDown={(e) =>
    ['e', '.', '+', '-'].includes(e.key) && e.preventDefault()
  }
/>
{formErrors[`score_${index}`] && (
  <div className="invalid-feedback">{formErrors[`score_${index}`]}</div>
)}



              <button
                type="button"
                onClick={() => toggleDQ(index)}
                style={{
                  backgroundColor: playerDQ[index] ? '#dc3545' : '#6c757d', // Red for active, gray for inactive
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  padding: '5px 10px',
                  marginLeft: '10px'
                }}
              >
                {playerDQ[index] ? 'DQ' : 'DQ'}
              </button>
              <button
                type="button"
                onClick={() => deletePlayerField(index)}
                disabled={selectedPlayers.length <= 2}
                style={{ marginLeft: '10px' }}
              >
                Delete Player
              </button>
            </div>
          ))}
          <button type="button" onClick={addPlayerField}>Add Player</button>
        </div>
        <hr />
        <ul id="peopleList" className="list-group mt-3" />
        {selectedMajorIdentifier !== 'YES' && (
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={leftyVsRighty}
                onChange={(e) => setLeftyVsRighty(e.target.checked)}
              />
              Is this round considered Lefty vs Righties?
            </label>
          </div>
        )}
        <button onClick={saveRoundData}>Save Round</button>

    </form>
  );
};

export default DropdownComponent;
