import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const API_BASE_URL = process.env.REACT_APP_API_URL;


interface HandicapIndex {
  player_id: number;
  first_name: string;
  last_name: string;
  handicap_index: number;
}

interface Round {
  score_id: number;
  round_id: number;
  date: string;
  course_name: string;
  course_teebox_par: number;
  course_teebox_name: string;
  course_teebox_slope: number;
  course_teebox_rating: number;
  player_score: number;
  handicap_differential: number;
}

interface PlayerData {
  player_id: number;
  player_name: string;
  rounds: Round[];
}

const Handicaps = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [players, setPlayers] = useState<PlayerData[]>([]);
  const [expandedPlayers, setExpandedPlayers] = useState<Record<number, boolean>>({});
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState<{ key: keyof Round | null; direction: "asc" | "desc" }>({
    key: null,
    direction: "asc",
  });
  const [handicapIndexData, setHandicapIndexData] = useState<HandicapIndex[]>([]);

  const fetchHandicapIndex = async () => {
    try {
      const token = await getAccessTokenSilently();
      const res = await axios.get(`${API_BASE_URL}/handicap-index`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setHandicapIndexData(res.data);
    } catch (error) {
      console.error("Error fetching handicap index:", error);
    }
  };

  const fetchHandicaps = async () => {
    try {
      const token = await getAccessTokenSilently();
      const res = await axios.get(`${API_BASE_URL}/handicaps`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPlayers(res.data);
    } catch (error) {
      console.error("Error fetching handicap data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHandicapIndex();
    fetchHandicaps();
  }, []);

  const toggleExpand = (playerId: number) => {
    setExpandedPlayers((prev) => ({ ...prev, [playerId]: !prev[playerId] }));
  };

  const handleSort = (playerId: number, key: keyof Round) => {
    const player = players.find((p) => p.player_id === playerId);
    if (!player) return;

    let direction: "asc" | "desc" = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedRounds = [...player.rounds].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });

    // Update only the sorted rounds for that player
    setPlayers(players.map((p) => (p.player_id === playerId ? { ...p, rounds: sortedRounds } : p)));
  };

  return (
    
    <div className="container mx-auto p-4">
      <div className="overflow-x-auto mb-6" style={{ paddingBottom: '20px' }} >
      <h2 className="text-xl font-bold mb-4">Current Handicap Index</h2>
        <table className="min-w-full border border-gray-300">
          <thead>
            <tr className="bg-gray-200 text-left">
              <th className="border px-4 py-2">Player Name</th>
              <th className="border px-4 py-2">Handicap Index</th>
            </tr>
          </thead>
          <tbody>
            {handicapIndexData.map((player) => (
              <tr key={player.player_id} className="border-b">
                <td className="border px-4 py-2">{player.first_name} {player.last_name}</td>
                <td className="border px-4 py-2">{player.handicap_index}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="overflow-x-auto">
          <h2 className="text-xl font-bold mb-4">Handicaps</h2>
          <table className="min-w-full border border-gray-300">
            <thead>
              <tr className="bg-gray-200 text-left">
                <th className="border px-4 py-2">Player</th>
                <th className="border px-4 py-2">Rounds</th>
              </tr>
            </thead>
            <tbody>
              {players.map((player) => (
                <React.Fragment key={player.player_id}>
                  {/* Player Row */}
                  <tr
                    className="bg-gray-100 cursor-pointer"
                    onClick={() => toggleExpand(player.player_id)}
                  >
                    <td className="border px-4 py-2">{player.player_name}</td>
                    <td className="border px-4 py-2 text-center">
                      {expandedPlayers[player.player_id] ? "▼" : "▶"}
                    </td>
                  </tr>

                  {/* Expandable Rounds Row */}
                  {expandedPlayers[player.player_id] && (
                    <tr>
                      <td colSpan={2} className="border px-4 py-2">
                        <table className="w-full border-collapse">
                          <thead>
                            <tr className="bg-gray-300 text-left">
                              <th className="border px-2 py-1 cursor-pointer" onClick={() => handleSort(player.player_id, "date")}>Date</th>
                              <th className="border px-2 py-1 cursor-pointer" onClick={() => handleSort(player.player_id, "course_name")}>Course</th>
                              <th className="border px-2 py-1 cursor-pointer" onClick={() => handleSort(player.player_id, "course_teebox_par")}>Par</th>
                              <th className="border px-2 py-1 cursor-pointer" onClick={() => handleSort(player.player_id, "course_teebox_name")}>Teebox</th>
                              <th className="border px-2 py-1 cursor-pointer" onClick={() => handleSort(player.player_id, "course_teebox_slope")}>Slope</th>
                              <th className="border px-2 py-1 cursor-pointer" onClick={() => handleSort(player.player_id, "course_teebox_rating")}>Rating</th>
                              <th className="border px-2 py-1 cursor-pointer" onClick={() => handleSort(player.player_id, "player_score")}>Score</th>
                              <th className="border px-2 py-1 cursor-pointer" onClick={() => handleSort(player.player_id, "handicap_differential")}>Score Diff.</th>
                            </tr>
                          </thead>
                          <tbody>
                            {player.rounds.map((round) => (
                              <tr key={round.score_id}>
                                <td className="border px-2 py-1">{new Date(round.date).toLocaleDateString()}</td>
                                <td className="border px-2 py-1">{round.course_name}</td>
                                <td className="border px-2 py-1">{round.course_teebox_par}</td>
                                <td className="border px-2 py-1">{round.course_teebox_name}</td>
                                <td className="border px-2 py-1">{round.course_teebox_slope}</td>
                                <td className="border px-2 py-1">{round.course_teebox_rating}</td>
                                <td className="border px-2 py-1">{round.player_score}</td>
                                <td className="border px-2 py-1">{round.handicap_differential}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Handicaps;
