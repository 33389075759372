import React, { useEffect, useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const API_BASE_URL = process.env.REACT_APP_API_URL;

interface ViewRoundByHoleProps {
  isOpen: boolean;
  onClose: () => void;
  roundId: number | null;
  getAccessTokenSilently: () => Promise<string>;
}

interface PlayerScore {
  player_id: number;
  firstName: string;
  lastName: string;
  team: string;
  scores: number[];
}

const ViewRoundByHole: React.FC<ViewRoundByHoleProps> = ({
  isOpen,
  onClose,
  roundId,
  getAccessTokenSilently,
}) => {
  
const [roundData, setRoundData] = useState<{
  course_name: string;
  round_date: string;
  course_teebox: string;
  number_of_holes: number;
  course_teebox_par: number;
  course_teebox_rating: number;
  course_teebox_slope: number;
  course_teebox_yards: number;
  holes: { holeNumber: number; par: number; yards: number }[];
  players: PlayerScore[];
} | null>(null);
const [editableScores, setEditableScores] = useState<{ [playerId: number]: number[] }>({});
  const [editedScores, setEditedScores] = useState<{ [playerId: number]: number[] }>({});



  useEffect(() => {
    
    if (!isOpen || !roundId) return;

    const fetchRoundData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(`${API_BASE_URL}/getRoundDetails?roundId=${roundId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok) throw new Error("Failed to fetch round details");

        const data = await response.json();
        console.log("Round Data Received:", data);
        setRoundData(data);
        setEditableScores(
          data.players.reduce((acc: { [id: number]: number[] }, player: any) => {
            acc[player.player_id] = [...player.scores];
            return acc;
          }, {})
        );      } catch (error) {
        console.error("Error fetching round data:", error);
      }
    };

    fetchRoundData();
  }, [roundId, isOpen, getAccessTokenSilently]);

  const formatDate = (dateString: string) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  if (!isOpen || !roundData) return null;

  // Function to calculate sums
  const sumFirstNine = (values: number[]) => values.slice(0, 9).reduce((acc, val) => acc + val, 0);
  const sumLastNine = (values: number[]) => values.slice(9, 18).reduce((acc, val) => acc + val, 0);
  const sumTotal = (values: number[]) => sumFirstNine(values) + sumLastNine(values);

  const handleScoreChange = (playerId: number, holeIndex: number, value: string) => {
    const parsed = parseInt(value);
    if (!isNaN(parsed)) {
      setEditableScores((prev) => ({
        ...prev,
        [playerId]: prev[playerId].map((score, i) => i === holeIndex ? parsed : score)
      }));
    }
  };
  
  

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {}} // Disable automatic closing
      shouldCloseOnOverlayClick={false} // Prevent closing on overlay click
      shouldCloseOnEsc={false} // Prevent closing on Esc key
      contentLabel="Round Breakdown"
    >
      <div>
        <div className="modal-header">
          <h2>{roundData.course_name}</h2>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="details-container">
          <span className="label">Round Date:</span>
          <span className="value">{formatDate(roundData.round_date)}</span>

          <span className="label">Teebox:</span>
          <span className="value">{roundData.course_teebox}</span>

          <span className="label">Number of Holes:</span>
          <span className="value">{roundData.number_of_holes}</span>

          <span className="label">Par:</span>
          <span className="value">{roundData.course_teebox_par}</span>

          <span className="label">Rating:</span>
          <span className="value">{roundData.course_teebox_rating}</span>

          <span className="label">Slope:</span>
          <span className="value">{roundData.course_teebox_slope}</span>

          <span className="label">Yards:</span>
          <span className="value">{roundData.course_teebox_yards}</span>
        </div>

        <table>
          <thead>
            <tr>
              <th>Hole</th>
              {roundData.holes.map((hole, index) => (
                <>
                  <th key={hole.holeNumber}>{hole.holeNumber}</th>
                  {index === 8 && <th key="OUT">OUT</th>}
                  {index === 17 && <th key="IN">IN</th>}
                </>
              ))}
              <th key="TOTAL">TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Par</td>
              {roundData.holes.map((hole, index) => (
                <>
                  <td key={hole.holeNumber}>{hole.par}</td>
                  {index === 8 && <td key="OUT">{sumFirstNine(roundData.holes.map((h) => h.par))}</td>}
                  {index === 17 && <td key="IN">{sumLastNine(roundData.holes.map((h) => h.par))}</td>}
                </>
              ))}
              <td key="TOTAL">{sumTotal(roundData.holes.map((h) => h.par))}</td>
            </tr>
            <tr>
              <td>Yards</td>
              {roundData.holes.map((hole, index) => (
                <>
                  <td key={hole.holeNumber}>{hole.yards}</td>
                  {index === 8 && <td key="OUT">{sumFirstNine(roundData.holes.map((h) => h.yards))}</td>}
                  {index === 17 && <td key="IN">{sumLastNine(roundData.holes.map((h) => h.yards))}</td>}
                </>
              ))}
              <td key="TOTAL">{sumTotal(roundData.holes.map((h) => h.yards))}</td>
            </tr>
            {roundData.players.map((player) => (
  <tr key={player.player_id}>
    <td>{player.firstName} {player.lastName} ({player.team})</td>
    {editableScores[player.player_id]?.map((score, i) => (
      <React.Fragment key={`${player.player_id}-${i}`}>
        <td>
          <input
            type="number"
            value={score}
            onChange={(e) => handleScoreChange(player.player_id, i, e.target.value)}
            style={{ width: "50px", textAlign: "center" }}
            min="1"
            max="20"
          />
        </td>
        {i === 8 && <td>{sumFirstNine(editableScores[player.player_id])}</td>}
        {i === 17 && <td>{sumLastNine(editableScores[player.player_id])}</td>}
      </React.Fragment>
    ))}
    <td>{sumTotal(editableScores[player.player_id])}</td>
  </tr>
))}
          </tbody>
        </table>
      </div>
      <button
  style={{ marginTop: '20px' }}
  onClick={async () => {
    try {
      const token = await getAccessTokenSilently();

      const payload = {
        roundId,
        players: roundData.players.map((player) => ({
          player_id: player.player_id,
          scores: editableScores[player.player_id],
        })),
      };
      

      const res = await fetch(`${API_BASE_URL}/updateRoundScores`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!res.ok) {
        throw new Error('Failed to update scores.');
      }

      alert('Scores updated successfully!');
    } catch (error) {
      console.error('Error saving scores:', error);
      alert('Error saving scores.');
    }
  }}
>
  Save Changes
</button>
<div style={{marginTop: '20px'}}>        <button onClick={onClose}>Close</button>
</div>

    </Modal>
  );
};

export default ViewRoundByHole;
