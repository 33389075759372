import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

interface Season {
  season_id: number;
  season_year: string;
}

interface SeasonFilterProps {
  selectedSeason: string;
  setSelectedSeason: (season: string) => void;
}

const SeasonFilter: React.FC<SeasonFilterProps> = ({ selectedSeason, setSelectedSeason }) => {
  const { getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const [seasons, setSeasons] = useState<Season[]>([]);
  const [error, setError] = useState<string | null>(null);
  const APIURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchSeasons = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get<Season[]>(`${APIURL}/seasons`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSeasons(response.data);
        if (response.data.length > 0 && !selectedSeason) {
          setSelectedSeason(response.data[0].season_year);
        }
      } catch (err) {
        setError('Failed to fetch seasons.');
      }
    };

    if (isAuthenticated) {
      fetchSeasons();
    } else if (!isLoading) {
      loginWithRedirect();
    }
  }, [getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect, APIURL, selectedSeason, setSelectedSeason]);

  if (error) return <p>{error}</p>;

  return (
    <label>
      Filter by Season:{' '}
      <select value={selectedSeason} onChange={(e) => setSelectedSeason(e.target.value)}>
        {seasons.map((season) => (
          <option key={season.season_id} value={season.season_year}>
            {season.season_year}
          </option>
        ))}
      </select>
    </label>
  );
};

export default SeasonFilter;
