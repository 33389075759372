import React, { useState, useEffect } from 'react';
import TournamentWrapper from '../components/TournamentWrapper'; // Adjust the path based on your file structure
import { useAuth0 } from '@auth0/auth0-react';


interface Tournament {
  tournament_id: number;
  tournament_name: string;
  start_date: string;
  end_date: string;
  tournament_status: string;
  tournament_team_enabled: boolean;
  type?: string;
}

const Majors: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState<Tournament[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const APIURL = process.env.REACT_APP_API_URL;


  const fetchTournaments = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${APIURL}/tournaments`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      const formattedData = responseData.map((tournament: Tournament) => ({
        ...tournament,
        start_date: tournament.start_date.split('T')[0], // Format to 'YYYY-MM-DD'
        end_date: tournament.end_date ? tournament.end_date.split('T')[0] : '',
      }));
      setData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  const deleteTournament = async (id: number) => {
    if (!window.confirm('Are you sure you want to delete this tournament?')) return;
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${APIURL}/tournaments/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        if (response.status === 409) {
          const errorData = await response.json();
          alert(errorData.error || 'Cannot delete tournament: associated rounds exist.');
        } else {
          throw new Error('Failed to delete tournament');
        }
        return;
      }
  
      fetchTournaments(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting tournament:', error);
      alert('Failed to delete tournament. Please try again.');
    }
  };
 
  
  const updateTournament = async (id: number, updates: Partial<Tournament>) => {
    try {
      const token = await getAccessTokenSilently();
      await fetch(`${APIURL}/tournaments/${id}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updates),
      });
      fetchTournaments(); // Refresh list after update
    } catch (error) {
      console.error('Error updating tournament:', error);
      alert('Failed to update tournament. Please try again.');
    }
  };
  

  const updateTournamentStatus = async (id: number, status: string) => {
    try {
      const token = await getAccessTokenSilently();
      await fetch(`${APIURL}/tournaments/${id}/status`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ tournament_status: status }),
      });
      fetchTournaments(); // Refresh the list after status update
    } catch (error) {
      console.error('Error updating tournament status:', error);
      alert('Failed to update tournament status. Please try again.');
    }
  };


  const updateTournamentType = async (id: number, type: string) => {
    try {
      const token = await getAccessTokenSilently();
      await fetch(`${APIURL}/tournaments/${id}/type`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ type }),
      });
      fetchTournaments(); // Refresh list after update
    } catch (error) {
      console.error('Error updating tournament type:', error);
      alert('Failed to update tournament type. Please try again.');
    }
  };
  
  useEffect(() => {
    fetchTournaments();
  }, [getAccessTokenSilently]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <div className="App">
        <TournamentWrapper onTournamentCreated={fetchTournaments} /> {/* Pass the callback function */}
      </div>
      <h2>Major Tournaments</h2>
      <table>
        <thead>
          <tr>
            <th>Tournament ID</th>
            <th>Tournament Name</th>
            <th>Type</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
            <th>Lefties vs Righties Enabled</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.tournament_id}>
              <td>{row.tournament_id}</td>
              <td>
  <input
    type="text"
    value={row.tournament_name}
    onChange={(e) =>
      updateTournament(row.tournament_id, { tournament_name: e.target.value })
    }
  />
</td>
              <td>
  <select
    value={row.type || ''}
    onChange={(e) => updateTournamentType(row.tournament_id, e.target.value)}
  >
    <option value="" disabled>Select..</option>
    <option value="League">League</option>
    <option value="Ad-Hoc">Ad-Hoc</option>
  </select>
</td>

<td>
  <input
    type="date"
    value={row.start_date}
    onChange={(e) =>
      updateTournament(row.tournament_id, { start_date: e.target.value })
    }
  />
</td>
<td>
  <input
    type="date"
    value={row.end_date}
    onChange={(e) =>
      updateTournament(row.tournament_id, { end_date: e.target.value })
    }
  />
</td>
              <td>
                <select
                  value={row.tournament_status}
                  onChange={(e) => updateTournamentStatus(row.tournament_id, e.target.value)}
                >
                  <option value="open">Open</option>
                  <option value="closed">Closed</option>
                </select>
              </td>
              <td>
  <select
    value={row.tournament_team_enabled ? 'true' : 'false'}
    onChange={(e) =>
      updateTournament(row.tournament_id, {
        tournament_team_enabled: e.target.value === 'true',
      })
    }
  >
    <option value="true">Yes</option>
    <option value="false">No</option>
  </select>
</td>
              <td>
                <button onClick={() => deleteTournament(row.tournament_id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Majors;
