import React, { useEffect, useState } from 'react';
import CreateRuleModal from '../components/CreateRuleModal'; // adjust path if needed
import 'bootstrap/dist/css/bootstrap.min.css';
import {Rule} from '../pages/types';
import { useAuth0 } from '@auth0/auth0-react';







const Rules: React.FC = () => {
  const APIURL = process.env.REACT_APP_API_URL;
  const [rules, setRules] = useState<Rule[]>([]);
  const [query, setQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<string | null>(null);
  const [selectedTopic, setSelectedTopic] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingRule, setEditingRule] = useState<Rule | null>(null);
  const { getAccessTokenSilently } = useAuth0();



  // for re-fetching after rule creation
  const fetchRules = async () => {
    try {
      const token = await getAccessTokenSilently();
  
      const res = await fetch(`${APIURL}/rules`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      if (!res.ok) {
        throw new Error(`Failed to fetch rules: ${res.status}`);
      }
  
      const data = await res.json();
      setRules(data);
    } catch (err) {
      console.error('Error loading rules:', err);
    }
  };
  
  
  useEffect(() => {
    fetchRules();
  }, []);
  

  // Extract unique filters
 const categories = Array.from(new Set(rules.map(r => r.category)));
const subCategories = selectedCategory
  ? Array.from(new Set(rules.filter(r => r.category === selectedCategory).map(r => r.sub_category)))
  : [];

const topics = selectedSubCategory
  ? Array.from(new Set(rules.filter(r =>
      r.category === selectedCategory &&
      r.sub_category === selectedSubCategory
    ).map(r => r.topic)))
  : [];

  const filteredRules = rules.filter(rule =>
    (!selectedCategory || rule.category === selectedCategory) &&
    (!selectedSubCategory || rule.sub_category === selectedSubCategory) &&
    (!selectedTopic || rule.topic === selectedTopic) &&
    [rule.category, rule.sub_category, rule.topic, rule.sub_topic, rule.description, rule.exceptions]
      .filter(Boolean)
      .join(' ')
      .toLowerCase()
      .includes(query.toLowerCase())
  );
  const [showModal, setShowModal] = useState(false);


  const handleDeleteRule = async (ruleId: number) => {
    if (!window.confirm('Are you sure you want to delete this rule?')) return;
  
    try {
      const token = await getAccessTokenSilently();
  
      const res = await fetch(`${APIURL}/rules/${ruleId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      if (!res.ok) throw new Error('Failed to delete rule');
  
      // Refresh the list
      fetchRules();
    } catch (err) {
      console.error('Error deleting rule:', err);
      alert('There was a problem deleting the rule.');
    }
  };
  

  return (

    
    <div className="flex min-h-screen">
<button
  className="btn btn-primary"
  onClick={() => {
    setEditingRule(null); // ← clear previous editing state
    setShowModal(true);
  }}
>
  + Create Rule
</button>


{showModal && (
  <>
    <div className="modal-backdrop show"></div>
    <div className="modal show d-block" tabIndex={-1} role="dialog">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{editingRule ? 'Edit Rule' : 'Add a New Rule'}</h5>
            <button
              type="button"
              className="close"
              onClick={() => setShowModal(false)}
            >
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <CreateRuleModal
              initialData={editingRule}
              onSuccess={() => {
                fetchRules();
                setShowModal(false);
              }}
              onClose={() => setShowModal(false)}
              getAccessTokenSilently={getAccessTokenSilently}
            />
          </div>
        </div>
      </div>
    </div>
  </>
)}




      {/* Left Sidebar */}
      <div className="w-64 bg-gray-100 p-4 border-r">
        <h2 className="font-semibold mb-2">Filter</h2>
        
        <div className="mb-4">
          <label className="block text-sm font-medium mb-1">Category</label>
          <select
            value={selectedCategory ?? ''}
            onChange={(e) => {
              setSelectedCategory(e.target.value || null);
              setSelectedSubCategory(null);
              setSelectedTopic(null);
            }}
            className="w-full p-2 border rounded"
          >
            <option value="">All Categories</option>
            {categories.map((cat, i) => (
              <option key={i} value={cat}>{cat}</option>
            ))}
          </select>
        </div>

        {subCategories.length > 0 && (
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Subcategory</label>
            <select
              value={selectedSubCategory ?? ''}
              onChange={(e) => {
                setSelectedSubCategory(e.target.value || null);
                setSelectedTopic(null);
              }}
              className="w-full p-2 border rounded"
            >
              <option value="">All Subcategories</option>
              {subCategories.map((sub, i) => (
                <option key={i} value={sub}>{sub}</option>
              ))}
            </select>
          </div>
        )}

        {topics.length > 0 && (
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Topic</label>
            <select
              value={selectedTopic ?? ''}
              onChange={(e) => setSelectedTopic(e.target.value || null)}
              className="w-full p-2 border rounded"
            >
              <option value="">All Topics</option>
              {topics.map((t, i) => (
                <option key={i} value={t}>{t}</option>
              ))}
            </select>
          </div>
        )}

        <div>
          <label className="block text-sm font-medium mb-1">Search</label>
          <input
            type="text"
            placeholder="Search keywords"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-6 overflow-y-auto">
        <h1 className="text-2xl font-bold mb-4">BGA Rulebook</h1>

        {filteredRules.length === 0 ? (
          <p className="text-gray-500">No matching rules found.</p>
        ) : (
          <div className="space-y-4">
            {filteredRules.map(rule => (
              <div key={rule.id} className="border p-4 rounded shadow">
              <h2 className="text-lg font-semibold">
                {rule.topic || rule.sub_category || rule.category}
              </h2>
              <p className="text-sm text-gray-500 italic">
                {rule.category} › {rule.sub_category}
                {rule.sub_topic ? ` › ${rule.sub_topic}` : ''}
              </p>
            
              <div
                className="mt-2 text-sm text-gray-800 leading-relaxed"
                dangerouslySetInnerHTML={{ __html: rule.description }}
              />
            
              {rule.exceptions && (
                <div className="mt-2 text-red-600 font-medium">
                  <strong>Exceptions:</strong>
                  <div
                    className="text-gray-800"
                    dangerouslySetInnerHTML={{ __html: rule.exceptions }}
                  />
                </div>
              )}
              <button
      className="btn btn-sm btn-outline-primary mt-4"
      onClick={() => {
        setEditingRule(rule);
        setShowModal(true);
      }}
    >
      ✏️ Edit
    </button>
    <button
  className="btn btn-sm btn-outline-danger ml-2 mt-4"
  onClick={() => handleDeleteRule(rule.id)}
>
  🗑️ Delete
</button>

            </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Rules;
