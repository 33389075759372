import React, { useState, useEffect } from 'react';
import DropdownComponent from './coursesDropdownComponent'; // Adjust the import path as needed
import { useAuth0 } from '@auth0/auth0-react';

interface ModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ show, onHide, title, children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState<any[]>([]);
  const APIURL = process.env.REACT_APP_API_URL;


  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(`${APIURL}/courses`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [getAccessTokenSilently]);

  return (
    <div className={`modal ${show ? 'd-block' : ''}`} tabIndex={-1} role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onHide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {children}

            <form id="roundForm">
  <div className="container">
    <DropdownComponent onClose={onHide} />
  </div>
</form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onHide}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
