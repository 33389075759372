import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/navbar.css';
import AuthButton from './AuthButton';

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar">
      <button className="navbar-toggle" onClick={toggleNavbar}>
        ☰ Navigation
      </button>
      <ul className={`navbar-links ${isOpen ? 'open' : ''}`}>
        <li><Link to="/rounds" onClick={handleLinkClick}>Rounds</Link></li>
        <li><Link to="/standings" onClick={handleLinkClick}>Standings</Link></li>
        <li><Link to="/majors" onClick={handleLinkClick}>Majors</Link></li>
        <li><Link to="/seasons" onClick={handleLinkClick}>Seasons</Link></li>
        <li><Link to="/players" onClick={handleLinkClick}>Players</Link></li>
        <li><Link to="/handicaps" onClick={handleLinkClick}>Handicaps</Link></li>
        <li><Link to="/courses" onClick={handleLinkClick}>Courses</Link></li>
        <li><Link to="/rules" onClick={handleLinkClick}>Rules</Link></li>
        <li><Link to="/about" onClick={handleLinkClick}>About</Link></li>
        <li><AuthButton /></li>
      </ul>
    </nav>
  );
};

export default Navbar;
