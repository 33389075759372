import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Modal from '../components/addNewRoundModal';
import ViewRoundByHole from '../components/ViewRoundByHole';
import SeasonFilter from '../components/seasonFilter'; // ✅ Import your reusable filter
import '../css/rounds.css';

const Rounds: React.FC = () => {
  const [tournaments, setTournaments] = useState<any[]>([]);
  const [selectedTournamentId, setSelectedTournamentId] = useState<number | null>(null);
  const { getAccessTokenSilently } = useAuth0();
  const [regularRounds, setRegularRounds] = useState<any[]>([]);
  const [tournamentRounds, setTournamentRounds] = useState<any[]>([]);
  const [modalShow, setModalShow] = useState(false);
  const [viewByHoleModalOpen, setViewByHoleModalOpen] = useState(false);
  const [selectedRoundId, setSelectedRoundId] = useState<number | null>(null);
  const [selectedSeason, setSelectedSeason] = useState<string>(''); // ✅ Season state

  const APIURL = process.env.REACT_APP_API_URL;

  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);
  const handleViewByHoleModalClose = () => setViewByHoleModalOpen(false);

  const handleViewByHoleModalShow = (roundId: number) => {
    console.log("Opening modal for roundId:", roundId);
    setSelectedRoundId(roundId);
    setViewByHoleModalOpen(true);
  };

useEffect(() => {
  if (!selectedSeason) return;

  const fetchTournaments = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${APIURL}/tournaments?season_year=${selectedSeason}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) throw new Error('Failed to fetch tournaments');
      const data = await response.json();
      setTournaments(data);
    } catch (err) {
      console.error('Error fetching tournaments:', err);
    }
  };

  fetchTournaments();
}, [APIURL, getAccessTokenSilently, selectedSeason]);


  /*const fetchTournamentRounds = useCallback(async () => {
    if (!selectedSeason) return;{tournaments.length > 0 && (
      <div style={{ margin: '1rem 0' }}>
        <label>
          Filter by Tournament:&nbsp;
          <select
            value={selectedTournamentId ?? ''}
            onChange={(e) => {
              const value = e.target.value;
              setSelectedTournamentId(value ? parseInt(value) : null);
            }}
          >
            <option value="">All Tournaments</option>
            {tournaments.map((t) => (
              <option key={t.tournament_id} value={t.tournament_id}>
                {t.tournament_name}
              </option>
            ))}
          </select>
        </label>
      </div>
    )}
    
  
    try {
      const token = await getAccessTokenSilently();
      let url = `${APIURL}/tournamentRounds?season_year=${selectedSeason}`;
  
      if (selectedTournamentId) {
        url += `&tournament_id=${selectedTournamentId}`;
      }
  
      const response = await fetch(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      if (!response.ok) throw new Error('Network response was not ok');
      const responseData = await response.json();
      setTournamentRounds(responseData);
    } catch (error) {
      console.error('Error fetching tournament rounds:', error);
    }
  }, [APIURL, getAccessTokenSilently, selectedSeason, selectedTournamentId]);*/
  
  

  const fetchRegularRounds = useCallback(async () => {
    if (!selectedSeason) return;

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${APIURL}/rounds?season_year=${selectedSeason}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) throw new Error('Network response was not ok');
      const responseData = await response.json();
      setRegularRounds(responseData);
    } catch (error) {
      console.error('Error fetching regular rounds:', error);
    }
  }, [APIURL, getAccessTokenSilently, selectedSeason]);

  const fetchTournamentRounds = useCallback(async () => {
    if (!selectedSeason) return;
  
    try {
      const token = await getAccessTokenSilently();
      let url = `${APIURL}/tournamentRounds?season_year=${selectedSeason}`;
  
      if (selectedTournamentId) {
        url += `&tournament_id=${selectedTournamentId}`;
      }
  
      const response = await fetch(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      if (!response.ok) throw new Error('Network response was not ok');
      const responseData = await response.json();
      setTournamentRounds(responseData);
    } catch (error) {
      console.error('Error fetching tournament rounds:', error);
    }
  }, [APIURL, getAccessTokenSilently, selectedSeason, selectedTournamentId]);
  

  useEffect(() => {
    fetchRegularRounds();
    fetchTournamentRounds();
  }, [fetchRegularRounds, fetchTournamentRounds]);

  const deleteRound = async (roundId: number) => {
    if (!window.confirm('Are you sure you want to delete this round?')) return;

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${APIURL}/rounds/${roundId}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) throw new Error('Failed to delete the round');

      setRegularRounds(regularRounds.filter(row => row.round_id !== roundId));
      setTournamentRounds(tournamentRounds.filter(row => row.round_id !== roundId));
    } catch (error) {
      console.error('Error deleting round:', error);
    }
  };

  const renderRoundTable = (data: any[], title: string) => (
    <div className="table-container">
      <h2>{title}</h2>
      <div className="table-header">
        <div className="table-row">
          <div className="table-cell">Date</div>
          <div className="table-cell">RoundID</div>
          <div className="table-cell">Course Name</div>
          <div className="table-cell">Course Teebox</div>
          <div className="table-cell">Scores</div>
          <div className="table-cell">Actions</div>
        </div>
      </div>
      <div className="table-body">
        {data.map((row, index) => (
          <div className="table-row" key={index}>
            <div className="table-cell" data-label="Date">{row.date}</div>
            <div className="table-cell" data-label="RoundID">{row.round_id}</div>
            <div className="table-cell" data-label="Course Name">{row.course_name}</div>
            <div className="table-cell" data-label="Course Teebox">{row.course_teebox_name}</div>
            <div className="table-cell" data-label="Scores">
              {row.player_scores ? row.player_scores.split(',').map((score: string, idx: number) => (
                <React.Fragment key={idx}>{score}<br /></React.Fragment>
              )) : 'No scores'}
            </div>
            <div className="table-cell" data-label="Actions">
              <button className="btn btn-info" onClick={() => handleViewByHoleModalShow(row.round_id)}>
                View Score by Hole
              </button>
              <button className="btn btn-danger" onClick={() => deleteRound(row.round_id)}>Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div>
      <div style={{ marginBottom: '1rem' }}>
        <SeasonFilter selectedSeason={selectedSeason} setSelectedSeason={setSelectedSeason} />
      </div>
      {tournaments.length > 0 && (
  <div style={{ margin: '1rem 0' }}>
    <label>
      Filter by Tournament:&nbsp;
      <select
        value={selectedTournamentId ?? ''}
        onChange={(e) => {
          const value = e.target.value;
          setSelectedTournamentId(value ? parseInt(value) : null);
        }}
      >
        <option value="">All Tournaments</option>
        {tournaments.map((t) => (
          <option key={t.tournament_id} value={t.tournament_id}>
            {t.tournament_name}
          </option>
        ))}
      </select>
    </label>
  </div>
)}
      <button className="btn btn-primary" onClick={handleModalShow}>Add New Round</button>
      <Modal 
        show={modalShow} 
        onHide={handleModalClose} 
        title="Adding a new round" 
        children={undefined}
      />

{!selectedTournamentId && renderRoundTable(regularRounds, "Regular Season Rounds")}
<br />
{renderRoundTable(tournamentRounds, selectedTournamentId ? "Major Rounds" : "Major Rounds")}


      <ViewRoundByHole
        isOpen={viewByHoleModalOpen}
        onClose={handleViewByHoleModalClose}
        roundId={selectedRoundId}
        getAccessTokenSilently={getAccessTokenSilently}
      />
    </div>
  );
};

export default Rounds;
