import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {Rule} from '../pages/types';
import { useAuth0, GetTokenSilentlyOptions } from '@auth0/auth0-react';



interface CreateRuleModalProps {
  onSuccess: () => void;
  onClose: () => void;
  initialData?: Rule | null;
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>;
}

interface RuleHierarchy {
  category: string;
  sub_category: string;
  topic: string;
  sub_topic: string | null;
}




const CreateRuleModal: React.FC<CreateRuleModalProps> = ({ onSuccess, onClose, initialData, getAccessTokenSilently }) => {

  const APIURL = process.env.REACT_APP_API_URL;
  const [form, setForm] = useState({
    category: initialData?.category || '',
    sub_category: initialData?.sub_category || '',
    topic: initialData?.topic || '',
    sub_topic: initialData?.sub_topic || '',
    description: initialData?.description || '',
    exceptions: initialData?.exceptions || ''
  });
  const [error, setError] = useState('');
  const [isCustomCategory, setIsCustomCategory] = useState(false);
const [isCustomSubCategory, setIsCustomSubCategory] = useState(false);
const [isCustomTopic, setIsCustomTopic] = useState(false);
const [isCustomSubTopic, setIsCustomSubTopic] = useState(false);


  const [hierarchy, setHierarchy] = useState<RuleHierarchy[]>([]);

  useEffect(() => {
    const fetchHierarchy = async () => {
      try {
        const token = await getAccessTokenSilently();
  
        const res = await fetch(`${APIURL}/rules/hierarchy`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
  
        if (!res.ok) throw new Error('Failed to load hierarchy');
  
        const data = await res.json();
        setHierarchy(data);
      } catch (err) {
        console.error('Error loading rule hierarchy:', err);
      }
    };
  
    fetchHierarchy();
  }, [getAccessTokenSilently, APIURL]);
  
  
  const categories = Array.from(new Set(hierarchy.map(h => h.category)));

const subCategories = form.category
  ? Array.from(
      new Set(
        hierarchy
          .filter(h => h.category === form.category)
          .map(h => h.sub_category)
      )
    )
  : [];

const topics = form.sub_category
  ? Array.from(
      new Set(
        hierarchy
          .filter(h =>
            h.category === form.category &&
            h.sub_category === form.sub_category
          )
          .map(h => h.topic)
      )
    )
  : [];

const subTopics = form.topic
  ? Array.from(
      new Set(
        hierarchy
          .filter(h =>
            h.category === form.category &&
            h.sub_category === form.sub_category &&
            h.topic === form.topic
          )
          .map(h => h.sub_topic)
      )
    )
  : [];


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm(prev => ({ ...prev, [name]: value }));
  };

  const isEditing = !!initialData?.id;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
  
    if (!form.category || !form.sub_category || !form.description) {
      return setError('Please fill out all required fields.');
    }
  
    try {
      const token = await getAccessTokenSilently();
  
      const res = await fetch(`${APIURL}/rules${isEditing ? `/${initialData.id}` : ''}`, {
        method: isEditing ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(form)
      });
  
      if (!res.ok) throw new Error('Failed to save rule');
  
      onSuccess();
      onClose();
    } catch (err) {
      console.error(err);
      setError('Error saving rule.');
    }
  };
  


  return (
    <form onSubmit={handleSubmit}>
      {error && <div className="alert alert-danger">{error}</div>}
      <label>Category</label>
      <select
  className="form-control"
  value={isCustomCategory ? '_custom' : form.category}
  onChange={(e) => {
    if (e.target.value === '_custom') {
      setIsCustomCategory(true);
      setForm(prev => ({ ...prev, category: '' }));
    } else {
      setIsCustomCategory(false);
      setForm(prev => ({
        ...prev,
        category: e.target.value,
        sub_category: '',
        topic: '',
        sub_topic: ''
      }));
    }
  }}
>
  <option value="">Select category</option>
  {categories.map((cat, i) => (
    <option key={i} value={cat}>{cat}</option>
  ))}
  <option value="_custom">+ Create new category</option>
</select>

{isCustomCategory && (
  <input
    type="text"
    className="form-control mt-2"
    placeholder="New category"
    value={form.category}
    onChange={(e) => setForm(prev => ({ ...prev, category: e.target.value }))}
  />
)}



<div className="form-group mt-3">
  <label>Subcategory</label>
  <select
    className="form-control"
    value={isCustomSubCategory ? '_custom' : form.sub_category}
    onChange={(e) => {
      if (e.target.value === '_custom') {
        setIsCustomSubCategory(true);
        setForm(prev => ({ ...prev, sub_category: '' }));
      } else {
        setIsCustomSubCategory(false);
        setForm(prev => ({
          ...prev,
          sub_category: e.target.value,
          topic: '',
          sub_topic: ''
        }));
      }
    }}
    disabled={!form.category}
  >
    <option value="">Select subcategory</option>
    {subCategories.map((sub, i) => (
      <option key={i} value={sub}>{sub}</option>
    ))}
    <option value="_custom">+ Create new subcategory</option>
  </select>

  {isCustomSubCategory && (
    <input
      type="text"
      className="form-control mt-2"
      placeholder="New subcategory"
      value={form.sub_category}
      onChange={(e) =>
        setForm(prev => ({ ...prev, sub_category: e.target.value }))
      }
    />
  )}
</div>



<div className="form-group mt-3">
  <label>Topic</label>
  <select
    className="form-control"
    value={isCustomTopic ? '_custom' : form.topic}
    onChange={(e) => {
      if (e.target.value === '_custom') {
        setIsCustomTopic(true);
        setForm(prev => ({ ...prev, topic: '' }));
      } else {
        setIsCustomTopic(false);
        setForm(prev => ({
          ...prev,
          topic: e.target.value,
          sub_topic: ''
        }));
      }
    }}
    disabled={!form.sub_category}
  >
    <option value="">Select topic</option>
    {topics.map((t, i) => (
      <option key={i} value={t}>{t}</option>
    ))}
    <option value="_custom">+ Create new topic</option>
  </select>

  {isCustomTopic && (
    <input
      type="text"
      className="form-control mt-2"
      placeholder="New topic"
      value={form.topic}
      onChange={(e) =>
        setForm(prev => ({ ...prev, topic: e.target.value }))
      }
    />
  )}
</div>


<div className="form-group mt-3">
  <label>Subtopic</label>
  <select
    className="form-control"
    value={isCustomSubTopic ? '_custom' : form.sub_topic ?? ''}
    onChange={(e) => {
      if (e.target.value === '_custom') {
        setIsCustomSubTopic(true);
        setForm(prev => ({ ...prev, sub_topic: '' }));
      } else {
        setIsCustomSubTopic(false);
        setForm(prev => ({ ...prev, sub_topic: e.target.value }));
      }
    }}
    disabled={!form.topic}
  >
    <option value="">Select subtopic</option>
    {subTopics.map((sub, i) => (
      <option key={i} value={sub ?? ''}>{sub ?? '(None)'}</option>
    ))}
    <option value="_custom">+ Create new subtopic</option>
  </select>

  {isCustomSubTopic && (
    <input
      type="text"
      className="form-control mt-2"
      placeholder="New subtopic"
      value={form.sub_topic ?? ''}
      onChange={(e) =>
        setForm(prev => ({ ...prev, sub_topic: e.target.value }))
      }
    />
  )}
</div>



      <div className="form-group">
  <label className="mb-1 d-block">Description</label>
  <div className="quill-wrapper">
    <ReactQuill
      value={form.description}
      onChange={(value) => setForm(prev => ({ ...prev, description: value }))}
    />
  </div>
</div>

<div className="form-group mt-4">
  <label className="mb-1 d-block">Exceptions</label>
  <div className="quill-wrapper">
    <ReactQuill
      value={form.exceptions}
      onChange={(value) => setForm(prev => ({ ...prev, exceptions: value }))}
    />
  </div>
</div>


      <button type="submit" className="btn btn-primary mt-4">Submit Rule</button>
    </form>
  );
};

export default CreateRuleModal;
